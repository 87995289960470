import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { EventListener } from '../../../../../lib/web/components/event-listener';
import { Input } from '../../../../../lib/web/components/input';
import { CountrySelectorComponent } from '../country-selector/country-selector.component';
import { TextBoxComponent } from '../text-box/text-box.component';

@Component({
    selector: '.a-country-box'
})
export class CountryBoxComponent extends ComponentBase<HTMLInputElement> {

    @Input('countries', { isJSON: true })
    private _countries: [{ id: number, name: string, flag: string, isDefault: boolean }];
    @Input('name')
    private _name: string = null;
    @Input('value')
    private _value: number = null;

    @ChildRef()
    private _textBox: TextBoxComponent = null;
    @ChildRef()
    private _selector: CountrySelectorComponent = null;

    public constructor(node: HTMLInputElement) {
        super(node);
    }

    public onInit(): void {
        (this.node as any).name = this._name;
        this.setDisplayValue();
    }

    private setDisplayValue(): void {
        let selected: { name: string } = null;
        if (this._value) {
            selected = this._countries.find(c => c.id == this._value);
        }
        this._textBox.value = selected ? selected.name: null;
    }

    @ClickListener('.a-country-box__change')
    public async onShowList(): Promise<void> {        
        const country: { id: number, name: string, flag: string, isDefault: boolean } = await this._selector.open(this.value);
        if (country) {
            this.value = country.id;
        }
    }
    
    // @EventListener('input', 'input')
    // public onBlur(): void {
    //     this.dispatchCustomEvent('input');
    // }

    public focus(): void {
        this._textBox.focus();
    }

    public get value(): number {
        return this._value;
    }

    public set value(value: number) {
        this._value = value;
        this.setDisplayValue();
    }
}