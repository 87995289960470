import { ChildRef } from '../../../../../lib/web/components/child-ref';
import { ChildrenRef } from '../../../../../lib/web/components/children-ref';
import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { MouseEnterListener } from '../../../../../lib/web/components/mouse-enter-listener';
import { MouseLeaveListener } from '../../../../../lib/web/components/mouse-leave-listener';

@Component({
    selector: '.o-carrousel'
})
export class CarrouselComponent extends ComponentBase<HTMLElement> {

    private _intervalTimer: NodeJS.Timer = null;
    private _isMouseEnter: boolean = false;

    @ChildrenRef(HTMLDivElement, '.o-carrousel__item')
    private _itemElements: HTMLDivElement[] = null;
    @ChildrenRef(HTMLDivElement, '.o-carrousel__bullet')
    private _bulletElements: HTMLButtonElement[] = null;
    @ChildRef('.o-carrousel__item-arrow--start')
    private _startArrowElement: HTMLDivElement;
    @ChildRef('.o-carrousel__item-arrow--end')
    private _endArrowElement: HTMLDivElement;

    public constructor(node: HTMLElement) {
        super(node);
    }

    public onInit(): void {
        this.move(0);
        this._intervalTimer = setInterval(() => this.move('next'), 5000);
        this._bulletElements.forEach(b => b.addEventListener('click', () => this.move(parseInt(b.dataset.index), true)));
    }

    @MouseEnterListener()
    public onMouseEnter(): void {
        this._isMouseEnter = true;
        this.setArrows();
    }

    @MouseLeaveListener()
    public onMouseOut(): void {
        this._isMouseEnter = false;          
        this._endArrowElement.style.display = 'none';      
        this._startArrowElement.style.display = 'none';
    }

    @ClickListener('.o-carrousel__item-arrow--start')
    public onStartArrowClick(): void {
        this.move('previous', true);
    }

    @ClickListener('.o-carrousel__item-arrow--end')
    public onEndArrowClick(): void {
        this.move('next', true);
    }
        
    private move(direction?: 'next' | 'previous' | number, force?: boolean): void {
        const index: number = typeof direction === 'number' ? direction: direction == 'next' ? this.nextIndex: this.previousIndex;
        if (force && this._intervalTimer) {
            clearInterval(this._intervalTimer);
            this._intervalTimer = null;
        }
        this._itemElements.forEach(s => s.dataset.active = 'false');
        const item: HTMLDivElement = this._itemElements[index];
        item.dataset.active = 'true';  
        this._bulletElements.forEach(s => s.dataset.active = 'false');
        const bullet: HTMLButtonElement = this._bulletElements[index];
        bullet.dataset.active = 'true';
        if (this._isMouseEnter)  {
            this.setArrows();
        }
    }

    private setArrows(): void {
        if (!document.body.classList.contains('touch')) {
            if (this.currentIndex != this.nextIndex) {
                this._startArrowElement.style.display = 'flex';
                this._endArrowElement.style.display =  'flex';
            }
        }
    }

    public get currentIndex(): number {
        return parseInt(this._itemElements.find(s => s.dataset.active === 'true').dataset.index);
    }

    public get nextIndex(): number {
        const currentIndex: number = this.currentIndex;
        if (currentIndex < this._itemElements.length - 1) {
            return currentIndex + 1;
        }
        else {
            return 0;
        }
    }

    public get previousIndex(): number {
        const currentIndex: number = this.currentIndex;
        if (currentIndex > 0) {
            return currentIndex - 1;
        }
        else {
            return this._itemElements.length - 1;
        }
    }
}