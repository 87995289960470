import { ClickListener } from '../../../../../lib/web/components/click-listener';
import { Component } from '../../../../../lib/web/components/component';
import { EventListener } from '../../../../../lib/web/components/event-listener';
import { ModalComponent } from '../../../../../lib/web/components/modal.component';
import { DictionaryObject } from '../../../../../lib/web/core/types';
import { ApiService } from '../../../services/api.service';
import { ValidationService } from '../../../services/validation.service';

@Component({
    selector: '.m-change-postal-code'
})
export class ChangePostalCodeComponent extends ModalComponent<HTMLInputElement> {

    private _country: number = null;

    public constructor(node: HTMLInputElement,
        private _apiService: ApiService,
        private _validationService: ValidationService) {
        super(node);
    }    

    public onInit(): void {
        super.onInit();
        this.registerFormFieldInput('.m-change-postal-code__form', field => this.validateField(field));
    }

    public async open(data: { postalCode: string, country: number }): Promise<any> {
        const { postalCode, country } = data;
        this._country = country;
        this.setFormData({ postalCode });   
        this._validationService.resetErrors('.m-change-postal-code__form', { postalCode });
        this.disable('.m-change-postal-code__save');
        this.focus('#txtPostalCode', 100);
        return super.open();
    }
    
    @EventListener( 'input', 'input')
    public onChange(): void {
        this.enable('.m-change-postal-code__save');
    }

    @ClickListener('.m-change-postal-code__cancel')
    public onCancel(): void {
        this.close();
    }

    @ClickListener('.m-change-postal-code__save')
    public onSave(): void {
        if (this.validate()) {
            const data: any = this.getFormData('.m-change-postal-code__form');
            this.close(data.postalCode);
        }
    }

    public validate(): boolean {
        const data: any = this.getFormData('.m-change-postal-code__form')
        const errors: DictionaryObject<string[]> = this._validationService.validatePostalCode(data, { country: this._country });
        this._validationService.showErrors('.m-change-postal-code__form', errors);
        return !this._validationService.hasErrors(errors);
    }

    public validateField(field: any): void {
        const postalCode = this._validationService.formatPostalCode(field.postalCode, { country: this._country });
        if (field.postalCode != postalCode) {
            this.setFormData({ postalCode });
        }
        const errors: DictionaryObject<string[]> = this._validationService.validatePostalCode(field, { country: this._country });
        this._validationService.showErrors('.m-change-postal-code__form', errors);        
    }
}