import { QueryString } from "../../../lib/web/core/query-string";
import { Injectable } from "../../../lib/web/reflection/injectable";
import { Injector } from "../../../lib/web/reflection/injector";
import { CookieService } from "../../../lib/web/services/cookie.service";
import { LocalStorageService } from "../../../lib/web/services/local-storage.service";
import { SessionStorageService } from "../../../lib/web/services/session-storage.service";
import { ToastService } from "../../../lib/web/services/toast.service";
import { RouterService } from './router.service';

export interface Session {
    user: { id?: string, email: string, pendingPostalCode: boolean, pictureUrl: string };
}

@Injectable({ type: 'singleton' })
export class SessionService {

    public constructor(private _cookieService: CookieService, 
        private _localStorageService: LocalStorageService,
        private _sessionStorageService: SessionStorageService,
        private _toastService: ToastService) {
    }   

    public init(): void {
        if (this.isSigned) {            
            this._localStorageService.set('localeSelectionShowed', true);
            this._toastService.hide('localeSelection');
            this.onUserSigned();            
        }
        else {
            this.checkGuestUser();
        }     
        this.setRedirectAfterSignIn();
    }

    private setRedirectAfterSignIn(): void {
        const redirectAfterSignIn: string = QueryString.get(location.search).redirectAfterSignIn;        
        const { pathname } = location;
        if (!pathname.startsWith('/u') || redirectAfterSignIn) {
            this._sessionStorageService.set('redirect-after-sign-in', redirectAfterSignIn || pathname);
        }
        else if (!pathname.startsWith('/u/sign-in')) {
            this._sessionStorageService.remove('redirect-after-sign-in');
        }
    }

    public get isSigned(): boolean {
        return this._cookieService.get('auth-token') != null;
    }

    public get isNewSession(): boolean {
        const isNewSession: boolean = this._sessionStorageService.get('new-session');
        return isNewSession != false;
    }

    public setNewSession(value: boolean) {
        this._sessionStorageService.set('new-session', value);
        this.onNewSession();
    }

    public set(session: Session, isRefresh?: boolean): void {
        this._localStorageService.set('session', session);
        if (!isRefresh) {
            this.onUserSigned();
            this.checkPendingData();
        }
    }

    public get(): Session {
        return this._localStorageService.get('session');
    }

    public remove(): void {
        this._cookieService.remove('auth-token');
        this._localStorageService.remove('session');
        this._localStorageService.remove('watching-history');
        this.onUserSignOut();
    }   

    public setLanguage(value: string): void {
        this._cookieService.set('language', value);
    }

    public setPictureUrl(value: string): void {
        if (this.isSigned) {
            const { user }= this.get();
            user.pictureUrl = value;
            this.set({ user }, true);
            window.dispatchEvent(new CustomEvent('user-picture-changed'));
        }
    }

    private onUserSignOut(): void {
        window.dispatchEvent(new CustomEvent('user-sign-out'));
        this._sessionStorageService.remove('pendingProfileDataShowed');
    }  

    private onUserSigned(): void {
        window.dispatchEvent(new CustomEvent('user-signed'));
    }

    private onNewSession(): void {
        window.dispatchEvent(new CustomEvent('new-session'));
    }

    public get sessionChecked(): boolean {
        return this._sessionStorageService.get('session-renewed') && this.get() != null;
    }

    public renewed(user: any): void {
        this._localStorageService.set('session', { user});
        this._sessionStorageService.set('session-renewed', true);
        this.checked();
    }

    public checked(): void {
        this.checkPendingData();
    }
    
    private async checkPendingData(): Promise<void> {
        if (this.isSigned) {
            const session: Session = this.get();
            if (session && session.user.pendingPostalCode && !this._sessionStorageService.get('pendingProfileDataShowed')) {
                await this._toastService.show('pendingProfileData', USER_LOCALE.pendingProfileData.format(USER_LOCALE.postalCode.toLowerCase()), [
                    { 
                        title: USER_LOCALE.later,
                        isDefault: true,
                        click: (resolve: (result?: any) => void) => { 
                            this._sessionStorageService.set('pendingProfileDataShowed', true);
                            resolve();
                        }
                    },
                    { 
                        title: USER_LOCALE.updateProfile,
                        click: (resolve: (result?: any) => void) => {                         
                            this._sessionStorageService.set('pendingProfileDataShowed', true);
                            resolve();
                            const routerService: RouterService = Injector.get(RouterService);
                            routerService.redirect('/u/profile');                    
                        }
                    },
                ]);
            }
        }
    }
   
    private async checkGuestUser(): Promise<void> {
        const localeSelectionShowed: boolean = this._localStorageService.get('localeSelectionShowed');
        if (!localeSelectionShowed) {
            await this._toastService.show('localeSelection', USER_LOCALE.localeSelection, Object.keys(CONFIG.ALLOWED_LOCALES).map((locale, index) => {
                return {
                    title: USER_LOCALE[`localeName${locale.toUpperCase()}`],
                    click: async (resolve: (result?: any) => void) => {
                        this._localStorageService.set('localeSelectionShowed', true);
                        resolve(); 
                        const selected: string = CONFIG.ALLOWED_LOCALES[locale][0];
                        if (selected != USER_LOCALE.localeId) {
                            window.dispatchEvent(new CustomEvent('change-language', { detail: selected }));
                        }
                    }
                };
            }));
        }
    }    
}