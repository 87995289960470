import { Component } from '../../../../../lib/web/components/component';
import { ComponentBase } from '../../../../../lib/web/components/component-base';
import { Input } from '../../../../../lib/web/components/input';
import { SessionService } from '../../../services/session.service';

@Component({
    selector: '.o-banner'
})
export class BannerComponent extends ComponentBase<HTMLElement> {

    @Input('parentSelector')
    private _parentSelector: string = null;
    @Input('audience', { isJSON: true })
    private _audience: string[] = null;

    public constructor(node: HTMLElement, private _sessionService: SessionService) {
        super(node);
    }

    public onInit(mode?: 'load' | 'redirect'): void {
        super.onInit(mode);
        this.checkVisibility();
    }

    private checkVisibility(): void {
        const visible: boolean = this._audience?.length ? this._audience.some(a => this.checkAudience(a)) : true;
        if (visible) {
            const parent: HTMLElement = this._node.closest(this._parentSelector);
            if (parent) {
                parent.classList.remove('u-hidden');
            }
        }
    }

    private checkAudience(audience: string): boolean {        
        switch (audience) {
            case 'anonymous-user':
                return !this._sessionService.isSigned;
            case 'signed-in-user':
                return this._sessionService.isSigned;
            default:
                return false;
        }
    }
}